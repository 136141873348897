import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const WorkGrid = ({ data }) => {
  const [workTypes] = data.allContentfulWorkTypesGrid.edges.map(
    node => node.node.workTypes
  )
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div id="work" className="col-12 col-lg-8 section text-center">
            <h1 className="section--title">Our work</h1>
            <p className="section--copy">
              Manchester Based Branding &amp; Signage Company also covering
              Stockport, Cheadle, Wilmslow, Altrincham, Cheshire and surrounding
              areas. We specialise in Shop Front Signage, Sales Displays,
              Vehicle Graphics, Vehicle Wrapping, Website Design, Logo Design
              &amp; more.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="work-wrapper">
          {workTypes.map((type, index) => {
            return (
              <div
                key={index}
                className={`work-item`}
                style={{
                  backgroundImage: `url(${type.backgroundImage.file.url}`,
                }}
              >
                <div className="mask" />
                <a className="work-item--link" href={type.slug}>
                  <h1 className="work-item--title">{type.workTitle}</h1>
                  <span>View &gt;</span>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulWorkTypesGrid {
          edges {
            node {
              id
              workTypes {
                workTitle
                backgroundImage {
                  id
                  file {
                    url
                  }
                }
                slug
              }
            }
          }
        }
      }
    `}
    render={data => <WorkGrid data={data} />}
  />
)
