import React from 'react'

const videoURL =
  'https://videos.ctfassets.net/igtoj5vpvagw/11TBYiFkTkXO8N4K6xFRG2/37073675817d620e94b81fd9b33b4341/dg_visual_promo.mp4'

export default () => (
  <header className="header--with-video">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 header">
          {/* <div className="hex logo" /> */}
          {/* <div className="hex yellow">
            <p>
              Multi-channel design &amp; branding agency based in the heart of
              Manchester
            </p>
          </div> */}
        </div>
      </div>
    </div>
    <video
      src={videoURL}
      autoPlay={true}
      loop={true}
      playsInline={true}
      muted={true}
    />
  </header>
)
