import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import HeroBanner from '../components/HeroBanner';
import OurWork from '../components/OurWork';
import OurServices from '../components/OurServices';
import Contact from '../components/Contact';

import Layout from '../components/layout';

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <HeroBanner />
        <OurWork />
        <OurServices />
        <Contact />
      </Layout>
    );
  }
}

export default RootIndex;
