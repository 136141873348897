import React from 'react';

export default () => (
  <React.Fragment>
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div id="services" className="col-12 col-lg-8 section text-center">
          <h1 className="section--title">Our services</h1>
          <p className="section--copy">We've got your image covered!</p>
          <p className="section--copy">
            We can take care of your logo design, website design, social media content, signage, vehicle graphics &amp;
            more. Anything that can be done in order to promote your business in a competitive industry can be taken
            care of by us.
          </p>
        </div>
      </div>
    </div>
    <div className="container-fluid services-banner-img">
      <div className="row">
        <div className="col-12">
          <div className="services">
            <div className="hex">
              <b>Signage</b>
            </div>
            <div className="hex">
              <b>Wall murals</b>
            </div>
            <div className="hex">
              <b>Illustration</b>
            </div>
            <div className="hex">
              <b>Branding</b>
            </div>
            <div className="hex">
              <b>
                Web design<br /> &amp; dev{' '}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);
